import { cloneDeep, throttle } from 'lodash'
import { post } from '@/utils/axios'

const Utils = {}

/**
 * 节流函数
 */
Utils.throttle = (func, wait = 50) => throttle(func, wait)

/**
 * 深度遍历，深拷贝
 */
Utils.deepClone = data => cloneDeep(data)

/**
 * 判断权限
 */
Utils.hasPermission = (permissions, route, user) => {
  if (user && user.admin) {
    return true
  }
  if (route.permission) {
    return permissions.some(permission => route.permission.indexOf(permission) > -1)
  }
  return true
}

Utils.uploadFile = async (file) => {
  const res = await post('/cms/file', file)
  if (res[0] && res[0].url) {
    return res[0].url
  }
}

export default Utils
