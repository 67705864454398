import Vue from 'vue'
import axios from 'axios'
import { getToken, saveAccessToken } from '@/utils/token'

const config = {
  baseURL: 'https://xxcms.mambaapp.com',
  timeout: 5 * 1000, // 请求超时时间设置
  validateStatus (status) { // 定义可获得的http响应状态码
    return status >= 200 && status < 510
  }
}

// 创建请求实例
const _axios = axios.create(config)

_axios.interceptors.request.use(
  originConfig => {
    const reqConfig = { ...originConfig }

    if (reqConfig.method === 'post') {
      // 检测是否包含文件类型, 若包含则进行 formData 封装
      let hasFile = false
      Object.keys(reqConfig.data).forEach(key => {
        if (typeof reqConfig.data[key] === 'object') {
          const item = reqConfig.data[key]
          if (item instanceof FileList || item instanceof File) {
            hasFile = true
          }
        }
      })

      // 检测到存在文件使用 FormData 提交数据
      if (hasFile) {
        const formData = new FormData()
        Object.keys(reqConfig.data).forEach(key => {
          formData.append(key, reqConfig.data[key])
        })
        reqConfig.data = formData
      }
    }

    if (reqConfig.url === 'cms/user/refresh') {
      const refreshToken = getToken('refresh_token')
      if (refreshToken) {
        reqConfig.headers.Authorization = refreshToken
      }
    } else {
      // 有access_token
      const accessToken = getToken('access_token')
      if (accessToken) {
        reqConfig.headers.Authorization = accessToken
      }
    }
    return reqConfig
  }
)

_axios.interceptors.response.use(
  async res => {
    const { code, message } = res.data
    if (res.status.toString().charAt(0) === '2') {
      return res.data
    }

    // assessToken相关，刷新令牌
    if (code === 10051) {
      const refreshResult = await _axios('cms/user/refresh')
      saveAccessToken(refreshResult.access_token)
      // 将上次失败请求重发
      const result = await _axios(res.config)
      return result
    }

    Vue.prototype.$message({
      message,
      type: 'error'
    })
    return res
  }
)

// 导出常用函数

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export function post(url, data = {}, params = {}) {
  return _axios({
    method: 'post',
    url,
    data,
    params
  })
}

/**
 * @param {string} url
 * @param {object} params
 */
export function get(url, params = {}) {
  return _axios({
    method: 'get',
    url,
    params
  })
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export function put(url, data = {}, params = {}) {
  return _axios({
    method: 'put',
    url,
    params,
    data
  })
}

export default _axios
