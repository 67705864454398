import homeRouter from './home-router'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/',
    component: () => import('../views/home/home.vue'),
    children: homeRouter
  }
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('../views/login/login.vue')
  // }
]

export default routes
