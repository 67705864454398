import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'

import '@/config/global'

import StickyTop from '@/components/base/sticky-top/sticky-top'

import '@/assets/style/index.scss'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false

Vue.use(ElementUI)

// base 组件注册
Vue.component('sticky-top', StickyTop)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
