// import categoryConfig from './category'
// import specConfig from './spec'
// import spuConfig from './spu'
// import skuConfig from './sku'

const homeRouter = [
  {
    route: '/',
    name: 'Index',
    title: 'Kroft',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: true
  },
  {
    route: '/Kroft',
    name: 'Index',
    title: 'Kroft',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: false
  },
  {
    route: '/SALRUS',
    name: 'Index',
    title: 'SALRUS',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: false
  },

  {
    route: '/Kastly',
    name: 'Index',
    title: 'Kastly',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: false
  },
  {
    route: '/Uniqcute',
    name: 'Index',
    title: 'Uniqcute',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: false
  },
  {
    route: '/TOPOCLEAN',
    name: 'Index',
    title: 'TOPOCLEAN',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: false
  },
  {
    route: '/MAKINO',
    name: 'Index',
    title: 'MAKINO',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: false
  },
  {
    route: '/Houseto',
    name: 'Index',
    title: 'Houseto',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: false
  },
  {
    route: '/Romanky',
    name: 'Index',
    title: 'Romanky',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: false
  },

  {
    route: '/SteelStar',
    name: 'Index',
    title: 'SteelStar',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: false
  },
  {
    route: '/dashboard1',
    name: 'DashBoard1',
    title: 'объем продаж',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: true
  },
  {
    route: '/dashboard2',
    name: 'DashBoard2',
    title: 'продажи',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: true
  },
  {
    route: '/dashboard3',
    name: 'DashBoard3',
    title: 'панель приборов',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: true
  },
  {
    route: '/dashboard4',
    name: 'DashBoard4',
    title: 'производительность',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: true
  },
  {
    route: '/dashboard5',
    name: 'DashBoard5',
    title: 'Просмотры',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: true
  },
  {
    route: '/dashboard6',
    name: 'DashBoard6',
    title: 'трафик',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: true
  },
  {
    route: '/dashboard7',
    name: 'DashBoard7',
    title: 'детали заказа',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: true
  },
  {
    route: '/dashboard8',
    name: 'DashBoard8',
    title: 'мой магазин',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: true
  },
  {
    route: '/dashboard9',
    name: 'DashBoard9',
    title: 'бренд',
    type: 'view',
    // icon: 'el-icon-pie-chart',
    filePath: 'views/dashboard/dashboard.vue',
    order: 0,
    inNav: true
  }
  // categoryConfig,
  // specConfig,
  // spuConfig,
  // skuConfig
]

export default homeRouter
