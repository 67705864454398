import Utils from '@/utils/utils'

export const logined = state => state.logined

export const user = state => state.user

/**
 * 在侧边栏展示时，如果当前路由 children 属性为空，则删除该路由
 * @param {*} arr 路由配置项数据
 */
function IterationDelateMenuChildren(arr) {
  if (arr.length) {
    for (const i in arr) {
      if (arr[i].children && !arr[i].children.length) {
        delete arr[i]
      } else if (arr[i].children && arr[i].children.length) {
        IterationDelateMenuChildren(arr[i].children)
      }
    }
  }
  return arr
}

/**
 * Shaking 掉无限制路由
 * @param {array} stageConfig 路由配置项数据
 * @param {array} permissions 当前登录管理员所拥有的权限集合
 * @param {object} currentUser 当前登录管理员
 */
function permissionShaking(stageConfig, permissions, currentUser) {
  const shookConfig = stageConfig.filter(route => {
    if (Utils.hasPermission(permissions, route, currentUser)) {
      if (route.children && route.children.length) {
        route.children = permissionShaking(route.children, permissions, currentUser)
      }
      return true
    }
    return false
  })
  return IterationDelateMenuChildren(shookConfig)
}

// 获取有权限的舞台配置
export const permissionStageConfig = state => {
  const { stageConfig, permissions, user } = state
  const tempStageConfig = Utils.deepClone(stageConfig)
  const shookConfig = permissionShaking(tempStageConfig, permissions, user)
  return shookConfig
}

export const sideBarList = (state, getter) => {
  const { permissionStageConfig } = getter
  function deepGetSideBar(target, level = 3) {
    if (Array.isArray(target)) {
      const acc = target.map(item => deepGetSideBar(item, level - 1))
      return acc.filter(item => item !== null)
    }

    // 检测是否需要在导航中显示
    if (!target.inNav) {
      return null
    }

    if (target.type === 'folder' && level !== 0) {
      // 处理 folder 模式
      const sideConfig = {}
      sideConfig.name = target.name
      sideConfig.title = target.title
      sideConfig.icon = target.icon
      sideConfig.path = target.route
      sideConfig.children = target.children.map(item => deepGetSideBar(item, level - 1))
      sideConfig.children = sideConfig.children.filter(item => item !== null)
      return sideConfig
    }

    // 处理一级就是 view 的情况
    if (target.type === 'view') {
      const sideConfig = {}
      sideConfig.name = target.name
      sideConfig.title = target.title
      sideConfig.icon = target.icon
      sideConfig.path = target.route
      return sideConfig
    }
  }
  const sideBar = deepGetSideBar(permissionStageConfig)
  return sideBar
}

export const getStageInfo = state => {
  const { stageConfig } = state
  const cache = {}
  const findStage = (stages, name) => {
    let result
    if (Array.isArray(stages)) {
      for (let i = 0; i < stages.length; i++) {
        result = findStage(stages[i], name)
        if (result) {
          break
        }
      }
      return result
    }

    if (stages.children && stages.children.length) {
      result = findStage(stages.children, name)
      return result
    }

    if (stages.name === name) {
      return stages
    }

    return false
  }
  return name => {
    if (cache[name]) {
      return cache[name]
    }
    const stageInfo = findStage(stageConfig, name)
    if (stageInfo) {
      cache[name] = stageInfo
    }
    return stageInfo
  }
}
