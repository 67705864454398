import Vue from 'vue'
import VueRouter from 'vue-router'
// import appConfig from '@/config/index'
// import store from '../store'
import routes from './route'

Vue.use(VueRouter)

// // 判断是否需要登录访问, 配置位于 config 文件夹
// let isLoginRequired = routeName => {
//   // 首次执行时缓存配置
//   let { notLoginRoute } = appConfig
//   const notLoginMark = {}

//   // 构建标记对象
//   if (Array.isArray(notLoginRoute)) {
//     for (let i = 0; i < notLoginRoute.length; i += 1) {
//       notLoginMark[notLoginRoute[i]] = true
//     }
//   }

//   notLoginRoute = null // 释放内存

//   // 重写初始化函数
//   isLoginRequired = name => {
//     if (!name) {
//       return true
//     }
//     return !notLoginMark[name]
//   }

//   return isLoginRequired(routeName)
// }

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 登录验证
  // if (isLoginRequired(to.name) && !store.state.logined) {
  //   next({ path: '/login' })
  //   return
  // }

  next()
})

export default router
